@use '../../sass/mixins';
@use '../../sass/themes';
@import '../../sass/variables';
@import './text/text';
@import './dropdown/dropdown';
@import './checkbox/checkbox';
@import './radio/radio';


label.dropdown {
	@include themes.theme-aware('color','neutral-400');
	@include themes.theme-aware('background-color','bg-default');
}

label:hover {
    cursor: pointer;
}

// Proper spacing between sibling input groups
.validation-message__wrapper + .validation-message__wrapper {
	@include mixins.toRem('margin-top', 16);
}

.validation-message {
	@include themes.theme-aware('color','bg-danger');
  	@include mixins.toRem('font-size', 14);
	@include mixins.toRem('margin-top', 4);
}

// Global style overrides for geosuggest addon
.geosuggest__input {
	@include mixins.toRem('border-width', 2);
	@include themes.theme-aware('border-color','border-primary');
	@include themes.theme-aware('color','neutral-400');
    @include mixins.padding(12, 16);
    appearance: none;
    border-radius: 0;
    border-style: solid;
    font-family: $sans; // font-family is required to style placeholder
}

.geosuggest__suggests {
	@include themes.theme-aware('border-color','border-primary');
	@include themes.theme-aware('color','neutral-400');
	@include mixins.toRem('border-width', 2);
	border-style: solid;
    box-shadow: $card-box-shadow;
}

.request-error {
    @include themes.theme-aware('color','bg-danger');
    margin-top: 1.5rem;
    text-align: center;
}


.form-group {
	margin-bottom: 1rem;
  }

  .form-group label {
	@include themes.theme-aware('color','text-subtle');
	font-size: .75rem;
	font-weight: 300;
	margin-bottom: .625rem;
	display: block;
  }

  .checkbox-skin {
	position: relative;
  }

  .checkbox-skin input[type="checkbox"] {
	left: -999px;
	opacity: 0;
	position: absolute;
  }

  .checkbox-skin input[type="checkbox"]:checked + label:before {
	@include themes.theme-aware('background-color','bg-default');
	@include themes.theme-aware('border-color','border-primary');
	@include themes.theme-aware('color','text-onAction');
	content: '\f00c';
	font-family: 'FontAwesome';
	font-size: .625rem;
	line-height: 0.813rem !important;
	text-align: center;
  }

  .checkbox-skin label {
	@include themes.theme-aware('color','text-subtle');
	font-size: 0.875rem;
	font-weight: 300;
	cursor: pointer;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }

  .checkbox-skin label:before {
	@include themes.theme-aware('border-color', 'border-default');
	@include mixins.toRem('border-width', 1);
	border-radius: 50%;
	border-style: solid;
	content: '';
	display: block;
	height: 0.938rem;
	left: 0;
	position: absolute;
	@include mixins.toRem('top', 1.5);
	width: 0.938rem;
  }

  .checkbox-skin label span {
	padding: 0.156rem 0.625rem 0 1.563rem;
	display: inline-block;
  }

.checkbox-skin-disabled {
	cursor: default !important;
}
// input:not([type="checkbox"]),
input:not([type="range"]),
textarea {
	@include themes.theme-aware('color', 'text');
	font-size: 0.875rem;
	height: 2.18rem;
	padding: 0 0.625rem;
	border-radius: 0.219rem;
	background-color: #fff !important;
	@include themes.theme-aware('border-color', 'border-default');
	@include mixins.toRem('border-width', .7);
	border-style: solid;
	width: -webkit-fill-available;
	width: fill-available;
	width: -moz-available;

}
input:not([type="checkbox"]).transparent,
select.transparent,
textarea.transparent {
	background-color: transparent !important;
}
